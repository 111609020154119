import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import {
  components,
} from "react-select";

//how to add label to react select
//https://codesandbox.io/s/6x9405rlqk?file=/example.js:1006-1020
export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="h-5 w-5 text-base-content/100" />
    </components.DropdownIndicator>
  );
};

export const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon className="h-5 w-5 text-base-content/100 hover:text-neutral-content" />
    </components.ClearIndicator>
  );
};

export const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <XMarkIcon className="h-5 w-5 text-base-content/100 hover:text-neutral-content" />
    </components.MultiValueRemove>
  );
};

export const SingleValue = (props) => {
  const { children, ...rest } = props;
  const { selectProps } = props;
  if (selectProps.menuIsOpen) return <Fragment></Fragment>;
  return <components.SingleValue {...rest}>{children}</components.SingleValue>;
}

export const getIconComponents = () => { return { DropdownIndicator, ClearIndicator, MultiValueRemove, SingleValue } }